<template>
  <span class="number-bubble">{{ number }}</span>
</template>

<script>
export default {
  props: ["number"],
};
</script>

<style lang="scss" scoped>
.number-bubble {
  display: inline-flex;
  position: absolute;
  top: $mpadding/4;
  right: $mpadding/4;
  min-width: $mpadding * 1.3;
  min-height: $mpadding * 1.3;
  background: $primary-color;
  color: #fff;
  font-size: 80%;
  align-items: center;
  justify-content: center;
  border-radius: 2pc;
}
</style>
